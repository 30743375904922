<template>
  <base-modal :title="$t('addnews')" @close="close">

    <ion-content>
      <ion-list lines="full" class="border-t border-gray-400">
        <ion-item>
          <ion-input type="text" :placeholder="$t('titlepost')" @ionInput="title = $event.target.value" />
        </ion-item>
        <ion-item>
          <ion-textarea ref="textArea" :placeholder="$t('writeapost')" rows="3" @ionInput="text = $event.target.value" @ionFocus="enableAutoGrow" />
        </ion-item>

        <ion-item v-if="image">
          <div :style="{ backgroundImage: `url(${image})` }" style="height:40vh;background-size:cover;width:100%" />
        </ion-item>


        <ion-input ref="picture" type="file" accept="image/*" style="display:none;" @ionInput="fileUpload($event)" />

        <ion-item button @click="uploadImage">
          <ion-icon slot="start" name="image" color="white" />


          <ion-label>
            {{ $t('addimage') }}
          </ion-label>
        </ion-item>
      </ion-list>

      <ion-list lines="full">
        <ion-radio-group :type="type" @ionChange="type = $event.target.value">
          <ion-list-header>
            <ion-label>{{ $t('newstype') }}</ion-label>
          </ion-list-header>

          <ion-item>
            <ion-label>{{ $t('news') }}</ion-label>
            <ion-radio slot="end" value="NEWS" color="moto" />
          </ion-item>

          <ion-item>
            <ion-label>{{ $t('knowledge') }}</ion-label>
            <ion-radio slot="end" value="KNOWLEDGE" color="moto" />
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </ion-content>

    <ion-footer>
      <Error :error="error" />
      <ion-button expand="full" @click="createNews">
        {{ $t('publish') }}
      </ion-button>
    </ion-footer>
  </base-modal>
</template>

<script>
    import { CREATE_NEWS } from '@/graphql/mutations';
    import BaseModal from './BaseModal';

    export default {
      components: {
        BaseModal
      },
      data() {
        return {
          error: null,
          title: '',
          file: null,
          image: '',
          text: '',
          type: 'NEWS'
        }
      },
      methods: {
        enableAutoGrow() {
          this.$refs.textArea.autoGrow = true;
        },
        async createNews() {
          try {
            const response = await this.$apollo.mutate({
              mutation: CREATE_NEWS,
              variables: {
                data: {
                  title:this.title,
                  image:this.file,
                  text: this.text,
                  type: this.type
                }
              }
            });

            if(!response?.errors) {
              const toast = await this.$ionic.toastController.create({
                color: 'success',
                duration: 3000,
                message: 'Bericht is gepubliceerd!'
              });

              await toast.present();

            }

            this.close()
          } catch(error) {
            // eslint-disable-next-line no-console
            console.error(error);
            this.error = error;
          }
        },
        uploadImage() {
          this.$refs.picture.getElementsByTagName('input')[0].click()
        },
        fileUpload(event) {
          this.file = event.target.getElementsByTagName('input')[0].files[0];

          this.image = this.file ? URL.createObjectURL(this.file) : undefined;
          this.$forceUpdate();
        },

        close() {
          this.$ionic.modalController.dismiss()
        }
      }
    }
</script>
