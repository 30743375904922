<template>
  <ion-page>
    <ContentContainer>
      <div class="max-w-xl mx-auto">
        <Refresher @refresh="doRefresh($event)" />

        <AdvertisementSlides ad-slot="top_news" margin-bottom />

        <ion-segment color="moto" class="top" @ionChange="segmentChanged($event)" style="box-shadow: 0;">
          <ion-segment-button value="NEWS" :checked="segmentChecked === 'NEWS'" class="news-category">
            <ion-label>{{ $t('news') }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="KNOWLEDGE" :checked="segmentChecked === 'KNOWLEDGE'" class="news-category">
            <ion-label>{{ $t('knowledge') }}</ion-label>
          </ion-segment-button>
        </ion-segment>

        <div v-if="userIsAdmin">
          <ion-list lines="full">
            <ion-item class="create-news" button @click="openCreateNewsModal">
              <ion-thumbnail slot="start">
                <img :src="`${$apiStorageUrl}/${user.profile_image}`">
              </ion-thumbnail>
              <ion-label color="white">
                <p>{{ $t('createnewpost') }}</p>
              </ion-label>
            </ion-item>
          </ion-list>
        </div>

        <NewsList v-if="segmentChecked === 'NEWS'" ref="newslist" />
        <KnowledgeList v-if="segmentChecked === 'KNOWLEDGE'" ref="knowledgelist" />
      </div>
    </ContentContainer>
  </ion-page>
</template>

<script>
  import * as moment from 'moment';
  import Modal from '@/components/modals/CreateNewsModal'
  import NewsList from "./NewsList";
  import KnowledgeList from "./KnowledgeList";
  import { mapGetters } from "vuex";
  import { CURRENT_USER_GETTER } from "@/store/store-getters";
  import AdvertisementSlides from "../../components/AdvertisementSlides";

  export default {
    components: {
      AdvertisementSlides,
      KnowledgeList,
      NewsList
    },
    filters: {
      newsDate(value) {
        return moment(value).format('dddd HH:mm')
      }
    },

    data() {
      return {
        segmentChecked: "NEWS",
        maxLength: 100,
      }
    },
    computed: {
      ...mapGetters({user: CURRENT_USER_GETTER}),
      userIsAdmin() {
        return this.$gate.can('create', 'news');
      }
    },
    methods: {
      openCreateNewsModal() {
        return this.$ionic.modalController
          .create({
            component: Modal,
            componentProps: {
              parent: this,
            },
          }).then(m => m.present());
      },
      async doRefresh(event) {
        if(this.$refs.newslist) {
          await this.$refs.newslist.doRefresh();
        }

        if(this.$refs.knowledgelist) {
          await this.$refs.knowledgelist.doRefresh();
        }

        event.target.complete();
      },
      segmentChanged(event) {
        this.segmentChecked = event.target.value;
      }
    }
  }
</script>

<style scoped>
    ion-textarea {
        --padding-start: 20px;
    }

    .create-news {
        --inner-padding-top: 20px;
        --inner-padding-bottom: 10px;
    }

    ion-segment.top {
        box-shadow: 0 0 3px #ccc;
        --background: var(--ion-color-secondary);
    }

    ion-segment.top ion-segment-button {
        --background: var(--ion-color-white);
    }

    ion-segment.top ion-segment-button {
        --border-radius: 0;
        --background-activated: var(--ion-color-white);
        --border-width: 3px;
        --background-checked: var(--ion-color-white);
        --border-color: var(--ion-color-primary);
        --border-style: solid;
        /* --padding-top: 10px; */
        /* --padding-bottom: 10px; */
    }

    ion-segment.top ion-segment-button.segment-button-checked {
        color: var(--ion-color-white) !important;
        background: var(--ion-color-secondary) !important;
        border-bottom: 2px solid var(--ion-color-moto);
    }

    ion-segment.top ion-segment-button {
        color: var(--ion-color-white) !important;
        background: var(--ion-color-secondary) !important;
    }

    .news-category {
      max-width: none;
    }
</style>
