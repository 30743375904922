<template>
  <div>
    <div class="mt-5">
      <div v-if="news && news.data && news.data.length">
        <NewsCard v-for="(newsItem, i) in news.data" :key="newsItem.id" :news-item="newsItem" :class="{ 'mt-0': (i == 0) }" />
      </div>
    </div>

    <SkeletonCards v-if="$apollo.loading && !news" />

    <InfiniteScroll @ionInfinite="loadMore($event, 'news')" />
  </div>
</template>

<script>
  import {GET_NEWS} from "@/graphql/queries";
  import NewsCard from "./NewsCard";
  import { infiniteScrollMixin } from '@/mixins';
  import { defaultPaginationConfig } from '@/configs';
  import { cloneDeep } from "lodash";

  export default {
    components: {
      NewsCard
    },
    mixins: [infiniteScrollMixin],
    data() {
      return {
        maxLength: 100,
        initialLoadReady: false
      }
    },
    methods: {
      async doRefresh() {
        this.resetInfiniteScroll();
        await this.$apollo.queries.news.refetch();
      },
    },
    apollo: {
      news: {
        query: GET_NEWS,
        update(data) {
          this.initialLoadReady = true;

          const news = cloneDeep(data.news);

          news.data = news.data.map((newsItem) => {
            return {
              ...newsItem,
              readMore: false,
            }
          })

          return news;
        },
        variables() {
          return {
            ...defaultPaginationConfig,
            type: 'KNOWLEDGE',
            orderBy: [{ column: "CREATED_AT", order: "DESC" }],
          }
        }
      }
    },
  }
</script>
